// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-album-apnee-content-js": () => import("./../../../src/pages/album-apnee-content.js" /* webpackChunkName: "component---src-pages-album-apnee-content-js" */),
  "component---src-pages-biographie-js": () => import("./../../../src/pages/biographie.js" /* webpackChunkName: "component---src-pages-biographie-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-credits-js": () => import("./../../../src/pages/credits.js" /* webpackChunkName: "component---src-pages-credits-js" */),
  "component---src-pages-dates-js": () => import("./../../../src/pages/dates.js" /* webpackChunkName: "component---src-pages-dates-js" */),
  "component---src-pages-discographie-js": () => import("./../../../src/pages/discographie.js" /* webpackChunkName: "component---src-pages-discographie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-page-cachee-pro-js": () => import("./../../../src/pages/page-cachee-pro.js" /* webpackChunkName: "component---src-pages-page-cachee-pro-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-disque-js": () => import("./../../../src/templates/disque.js" /* webpackChunkName: "component---src-templates-disque-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */)
}

